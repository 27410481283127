import React from "react"
import Layout from "../components/layout/Layout"
import HeroImage from "../components/HeroImage"
import SEO from "../components/SEO"
import { graphql, useStaticQuery } from 'gatsby'
import { ImHome } from "react-icons/im"
import { Link } from "gatsby"

const Privacy = (props) => {
    const {privacy} = useStaticQuery(graphql`
    query {
        privacy: file(relativePath: {eq: "page/privacy/index.md"}) {
            childMarkdownRemark {
                html
                frontmatter {
                    title
                    seotitle
                    seometadesc
                    featureimagealt
                    featureimage {
                        childImageSharp {
                            fluid(maxWidth: 4000, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp 
                            }
                        }
                    extension
                    publicURL
                    }
                }
            }
        }
    }
    `)

    const privacydata = privacy.childMarkdownRemark
    return (
        <>
            <Layout location={props.location}>
                <SEO title={privacydata.frontmatter.seotitle} description={privacydata.frontmatter.seometadesc} image={privacydata.frontmatter.featureimage.publicURL}/>
                <HeroImage markdown={privacydata} />
                <section className="container mx-auto">
                    <div className="text-left px-4 pt-4 pb-4">
                      <ul className="text-left text-xs md:text-base">
                        <li className="inline-block">
                          <Link to="/">
                              <span className="inline-block pr-2"><ImHome /></span>
                              <span className="px-1">Home</span>
                          </Link>
                        </li>
                        <li className="inline-block"><span>·</span></li>
                        <li className="inline-block">
                          <span className="px-1">{privacydata.frontmatter.seotitle}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="mx-auto px-2 lg:flex lg:w-2/3">
                        <div className="lg:text-left p-6">
                            <h3 className="text-3xl lg:text-5xl font-thin tracking-widest">{privacydata.frontmatter.title}</h3>
                            <div className="text-left lg:text-justify cmscontent" dangerouslySetInnerHTML={{ __html: privacydata.html }} />
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Privacy